import React from "react";

const Contact = () => {
    return (
        <div className='contactSection'>
            <div className='contactWrapper'>
                <div className='contactHeadingDiv contactItem1 '>
                    <h3 className='contactSubHeading'>Ready to Start Your Training?</h3>
                    <h2><span className='textHighlight'>Contact</span> Us</h2>
                </div>
                <div className='contactFormDiv contactItem2 '>
                    <div>
                        <div className='contactFormHeadingDiv'>
                            <h3 className='contactFormHeading'>Shoot Us a <span className='textHighlight'>Message</span></h3>
                        </div>
                        <div className='mobileContactFormHeadingDiv'>
                            <h3 className='contactMobileSubHeading'>Ready to Start Your Training?</h3>
                            <h2 className='contactMobileHeading'><span className='textHighlight'>Contact</span> Us</h2>
                        </div>
                        <form method="POST" 
                            action="https://submit-form.com/UTLgWjQ4Z" 
                            data-botpoison-public-key="pk_b1dfc7a9-9f0c-4c96-b59c-742707f9516e" 
                            target="_blank" 
                            onSubmit={(e) => {
                                // Prevent the inlineRadioOptions from being sent to form
                                const radioButtons = document.getElementsByName('inlineRadioOptions');
                                for (const radioButton of radioButtons) {
                                    radioButton.removeAttribute('name');
                                }
                            }}>
                            <div className="row">
                                <div className="col">
                                    <input type="text" name='First name' className="form-control" id='contactFirstName' placeholder="First name" required/>
                                </div>
                                <div className="col">
                                    <input type="text" name='Last name' className="form-control" id='contactLastName' placeholder="Last name" required/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="email" name='Email' className="form-control" id="InputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="tel" name='Phone' className="form-control" id="phoneNumber" placeholder="Phone" required/>
                                </div>
                            </div>

                            <input type="hidden" name="Permission To Text" id="PermissionToText" />

                            <div className="row mt-3">
                                <label className='smsText' htmlFor="smsText">Do we have permission to text you?</label>
                                <div className="col">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" 
                                            type="radio"
                                            name="inlineRadioOptions" 
                                            id="inlineRadio1" 
                                            value="Yes" 
                                            onChange={() => {
                                                document.getElementById('PermissionToText').value = 'Yes';
                                            }}  
                                            required/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" 
                                            type="radio" 
                                            name="inlineRadioOptions" 
                                            id="inlineRadio2" 
                                            value="No" 
                                            onChange={() => {
                                                document.getElementById('PermissionToText').value = 'No';
                                            }} 
                                            required/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="text" name='Subject' className="form-control" id="subject" placeholder="Subject" required/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <textarea className="form-control" name='Message' id="messageBox" rows="4" placeholder="Your message" required></textarea>
                                </div>
                            </div>
                            <div className="contactFormBtnDiv">
                                <button className="contactFormSubmitBtn" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;