import './styles.css';
import Home from './pages/Home';
import Classes from './pages/Classes';
import About from './pages/About';
import Blog from './pages/Blog';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/classes' element={<Classes/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/blog' element={<Blog/>}></Route>
        </Routes>
    </div>
  );
}

export default App;
