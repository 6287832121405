import React from "react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
    const location = useLocation();
    let navbarClass = 'navbar-default';
    // Set navbar class based on the current path
    if (location.pathname === '/classes' || location.pathname === '/about' || location.pathname === '/booking' || location.pathname === '/blog' || location.pathname === '/contactus')  {
        navbarClass = 'navbar-black'; 
    }

    return (
        <div className='navbarSection'>
            <div className='promoMsg'>
                <h3 className='promoMsgTxt'>Save $100 when you bring a friend! Train Together, Save Together, Book Now!</h3>
            </div>

            <div>
                <nav className={`navbar ${navbarClass}`}>
                    <div className="desktopNavbar">
                        <div className='navHalf1'>
                            <a className='nav-link' href='/blog'>Blog</a>
                            <a className='nav-link' href='/about'>About</a>
                        </div>

                        <div className='navLogo'>
                            <a className='nav-link' href='/'><img className='navLogo' src='/imgs/navLogo.svg' alt='Defense Therapy Logo' /></a>
                        </div>

                        <div className='navHalf2'>
                            <a className='nav-link' href='/classes'>Classes</a>
                            <a className='nav-link scheduleBtn' href='https://squareup.com/appointments/buyer/widget/goglug4njcvntf/LMHW1P0587EWV' target="_blank" rel="noopener noreferrer">Schedule</a>
                        </div>
                    </div>

                    <div className='mobileNavbar'>
                        <div className='mobileNavbarWrapper container'>
                            <div className='navLogo'>
                                <a className='nav-link' href='/'><img className='navLogo' src='/imgs/navLogo.svg' alt='Defense Therapy Logo' /></a>
                            </div>
                            <div>
                                <button className="mobileMenuBtns" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <span><img className='barsIcon' src='/imgs/barsIcon.svg' alt='mobile menu icon' /></span>
                                </button>
                                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                    <div className="offcanvas-header">
                                        <h5>Defense Therapy</h5>
                                        <button className='mobileMenuBtns' type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                            <span><img className='barsIcon' src='/imgs/xIcon.svg' alt='mobile menu icon' /></span>
                                        </button>
                                    </div>
                                    <div>
                                        <ul className='mobileNavItems'>
                                            <li className="nav-item mobileNavItem">
                                                <a className="nav-link" href="/">Home</a>
                                            </li>
                                            <li className="nav-item mobileNavItem">
                                                <a className='nav-link' href='https://squareup.com/appointments/buyer/widget/goglug4njcvntf/LMHW1P0587EWV' target="_blank" rel="noopener noreferrer">Schedule</a>
                                            </li>
                                            <li className="nav-item mobileNavItem">
                                                <a className="nav-link" href="/classes">Classes</a>
                                            </li>
                                            <li className="nav-item mobileNavItem">
                                                <a className="nav-link" href="/about">About</a>
                                            </li>
                                            <li className="nav-item mobileNavItem">
                                                <a className="nav-link" href="/blog">Blog</a>
                                            </li>
                                            <li>
                                                <a className='nav-link' href='/'><img className='mobileNavOffCanvasLogo' src='/imgs/navLogo.svg' alt='Defense Therapy Logo' /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;

