import React from "react";

const Programs = () => {
    return (
        <div className='programsSection'>
            <div className='programsContent container'>
                <h2 className='programsHeading'><span className='textHighlight'>Firearms Training </span>| Programs</h2>
                <div className='programsList'>
                    <div className='programItem'>
                        <div><img className='bulletHole' src='/imgs/bulletHole.svg' alt='bullet hole'></img></div>
                        <div><p className='programsText'>Basic Firearms Training</p></div>                     
                    </div>
                    <div className='programItem'>
                        <div><img className='bulletHole' src='/imgs/bulletHole.svg' alt='bullet hole'></img></div>
                        <div><p className='programsText'>Intermediate Training</p></div>                     
                    </div>
                    <div className='programItem'>
                        <div><img className='bulletHole' src='/imgs/bulletHole.svg' alt='bullet hole'></img></div>
                        <div><p className='programsText'>Advanced Tactical Training</p></div>                     
                    </div>
                    <div className='programItem'>
                        <div><img className='bulletHole' src='/imgs/bulletHole.svg' alt='bullet hole'></img></div>
                        <div><p className='programsText'>Concealed Carry Classes</p></div>                     
                    </div>
                    <div className='programItem'>
                        <div><img className='bulletHole' src='/imgs/bulletHole.svg' alt='bullet hole'></img></div>
                        <div><p className='programsText' tabIndex="0">Women's Only Classes</p></div>                     
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Programs;