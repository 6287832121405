import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Faqs from "../components/Faqs";
import Booking from "../components/Booking";
import { Helmet } from "react-helmet-async";

const Classes = () => {
    return (
        <div>
            <Helmet>
                <title>Defense Therapy | Firearm Classes</title>
                <meta 
                    name="description" 
                    content="See what classes and training Defense Therapy has to offer: Basic Firearms Training, Concealed Carry Courses, and Maryland HQL Courses."
                    data-rh="true"> 
                </meta>
                <link rel="canonical" href="/classes"></link>
            </Helmet>
            <Navbar/>
            <div className='classesPage'>
                <div className='classesPs'>
                    <div className='classesPsImg1 classPsDiv classesPsDiv'> 
                        <h3 className='classesPsHeading'>Maryland CCW</h3>   
                    </div>
                    <div className='classesPsText classesPsDiv container'>
                        <div><p className='classesPsPara'>Our Maryland CCW class equips you with the essential knowledge and skills for legal concealed carry. Covering Maryland laws, firearm safety, handgun operation, marksmanship, and concealed carry techniques, the course includes scenario-based training for real-life situations. Taught by experienced instructors in a state-of-the-art facility, you’ll receive personalized attention and ongoing support. Complete the course to fulfill Maryland’s training requirements for the Wear and Carry Permit. </p></div>
                    </div>
                </div>

                <div className='classesPsRev'>
                    <div className='classesPsImg2 classPsDiv classesPsDiv'>   
                        <h3 className='classesPsHeading'>Multi-Jurisdictional CCW</h3>   
                    </div>
                    <div className='classesPsText classesPsDiv container'>
                        <div>
                            <p className='classesPsPara'>
                                The Multi-Jurisdictional CCW class offered by Defense Therapy covers concealed carry laws for 
                                Maryland, DC, Virginia, and states with reciprocity. This comprehensive course equips you with 
                                the knowledge and skills needed to legally carry in multiple jurisdictions. Maryland residents 
                                receive a free Handgun Qualification License (HQL) as part of the course, and the Arizona CCW 
                                certification is also included, providing broader coverage for additional states. Ideal for those 
                                looking to expand their carry privileges across the DMV and beyond.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='classesPs'>
                    <div className='classesPsImg3 classPsDiv classesPsDiv'>   
                        <h3 className='classesPsHeading'>Pistol Safety and Marksmanship</h3>     
                    </div>
                    <div className='classesPsText classesPsDiv container'>
                        <div>
                            <p className='classesPsPara'>
                                Join our 1-hour Basic Pistol Safety and Marksmanship class to learn essential handgun safety, operation, 
                                and basic shooting techniques. Perfect for beginners, this quick, comprehensive course covers safe handling, 
                                proper grip, stance, and aiming. Taught by experienced instructors, you’ll gain confidence and foundational 
                                skills for responsible firearm use. Enroll now to start your journey in safe and accurate pistol shooting!
                            </p>
                        </div>
                    </div>
                </div>

                <div className='classesPsRev'>
                    <div className='classesPsImg4 classPsDiv classesPsDiv'>   
                        <h3 className='classesPsHeading'>Gun Tastings</h3>     
                    </div>
                    <div className='classesPsText classesPsDiv container'>
                        <div>
                            <p className='classesPsPara'>
                                Join our Gun Tastings event to try various pistols and rifles before buying. Perfect for exploring options, 
                                this session lets you test different firearms with expert guidance. Learn about features, handling, and 
                                performance in a safe environment. Find the perfect fit for your needs and preferences. Sign up for an 
                                informative, hands-on experience!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Booking/>
            </div>
            <div className='faqsClassesPs'>
                <Faqs/>
            </div>
            
            <div className='footerClassesPs'>
                <Footer/>
            </div>
        </div>
    )
}
export default Classes;