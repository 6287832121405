import React from "react";
import { useLocation } from "react-router-dom";

const Booking = () => {
    const location = useLocation();
    let bookingSectionClass = 'bookingSection-default';
    let bookingSectionHeading = 'bookingHeading-default';
    // Set booking section class based on the current path
    if (location.pathname === '/classes')  {
        bookingSectionClass = 'bookingSection-White'; 
        bookingSectionHeading = 'bookingHeading-White';
    }
    return (
        <div className={`bookingSection ${bookingSectionClass}`}>
            <div className=" bookingContent container"> 
                <h2 className={`${bookingSectionHeading}`}>Ready to Shoot? </h2>
                <a className="squareBookingBtn" href="https://squareup.com/appointments/buyer/widget/goglug4njcvntf/LMHW1P0587EWV" target="_blank" rel="noopener noreferrer">
                    Sign Me Up!
                </a>
            </div>
        </div>
    );
}

export default Booking;
