import React from "react";

const Faqs = () => {
    return (
        <div className='faqsSection'>
            <div className='container'>
                <div>
                    <h2 className='faqsHeading'><span className='textHighlight'>FAQ</span>s</h2>
                </div>
                <div>
                    <div className="accordion" id="faqAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is the difference between a basic pistol class and a CCW class?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    A basic pistol class teaches firearm safety, operation, and marksmanship. A CCW class covers those basics but also focuses on laws, regulations, and defensive scenarios for carrying a concealed firearm.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Do I need to take the Maryland Handgun Qualification License (HQL) course to purchase a handgun?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    Yes, Maryland law requires the HQL course for most residents to purchase a handgun, with a few exceptions such as law enforcement and military personnel.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What are the prerequisites for taking a concealed carry class in the DMV area?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    Prerequisites vary by state. Generally, you must be 21 years old, pass a background check, and complete a state-approved training course.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How long is my certification valid after completing a CCW or HQL course?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div className="accordion-body" tabIndex="0">
                                    CCW permits typically last 2-5 years depending on the state. Maryland's HQL is valid for 10 years.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What equipment or materials do I need to bring to my firearms training class?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    You should bring a valid photo ID, a firearm if you have one (or rent one from us), hearing and eye protection, and ammunition. We provide targets and other materials.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Faqs;