import React from "react";
import Navbar from "../components/Navbar";
import Programs from "../components/Programs";
import Philosophy from "../components/Philosophy";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const About = () => {
    return (
        <div>
            <Helmet>
                <title>Defense Therapy | About Us</title>
                <meta 
                    name="description" 
                    content="Defense Therapy: Equipping individuals with responsible firearm skills, emphasizing safety, proficiency, and confidence for all experience levels."
                    data-rh="true"> 
                </meta>
                <link rel="canonical" href="/about"></link>
            </Helmet>
            <Navbar/>
            <div className='aboutPage'>
                <div className='aboutPageHeadDiv'>
                    <h2 className='aboutPageHeading container'><span className='textHighlight'>About</span> Defense Therapy</h2>
                </div>
                <div className='aboutPageAboutParaDivDesktop'>
                    <p className='aboutPageAboutPara container'>At Defense Therapy, we are dedicated to equipping individuals with the skills and knowledge needed to handle firearms with responsibility and confidence. Our training philosophy emphasizes safety, proficiency, and self-assurance, fostering a secure learning environment for students of all levels. We offer a range of courses designed to meet diverse needs: from Basic Firearms Training, which covers the essentials of firearm safety, handling, and shooting, to Intermediate Training that focuses on marksmanship, situational awareness, and defensive techniques. For those seeking advanced skills, our Advanced Tactical Training includes dynamic shooting, combat scenarios, and home defense strategies. We also provide Concealed Carry Classes for certification and training to obtain a concealed carry permit, Women’s Only Classes for specialized instruction in a supportive environment, and Private Lessons for personalized, one-on-one coaching tailored to your goals.</p>
                </div>
            </div>

            <div className='aboutPageAboutParaDivMobile'>
                <div className="container">
                    <p className='aboutPageAboutPara'>At Defense Therapy, we are dedicated to equipping individuals with the skills and 
                        knowledge needed to handle firearms with responsibility and confidence. Our training 
                        philosophy emphasizes safety, proficiency, and self-assurance, fostering a secure 
                        learning environment for students of all levels. We offer a range of courses designed 
                        to meet diverse needs: from Basic Firearms Training, which covers the essentials of 
                        firearm safety, handling, and shooting, to Intermediate Training that focuses on 
                        marksmanship, situational awareness, and defensive techniques. 
                    </p>
                    <p id="moreContent" className="aboutPageAboutPara collapse">
                        For those seeking advanced 
                        skills, our Advanced Tactical Training includes dynamic shooting, combat scenarios, and 
                        home defense strategies. We also provide Concealed Carry Classes for certification and 
                        training to obtain a concealed carry permit, Women’s Only Classes for specialized 
                        instruction in a supportive environment, and Private Lessons for personalized, one-on-one 
                        coaching tailored to your goals.
                    </p>
                    <button className="aboutPageReadMoreBtn" data-bs-toggle="collapse" data-bs-target="#moreContent" aria-expanded="false" aria-controls="moreContent" 
                            onclick="this.innerHTML = this.innerHTML === 'Read More' ? 'Read Less' : 'Read More'">
                        Read More...
                    </button>
                </div>
            </div>

            <div className='getToKnowWrapper'>
                <div className='getToKnowDiv getToKnowDivText container'>
                    <h2 className='getToKnowHeading getToKnowHeadingDesktop'>Get to know your <span class='textHighlight'>Instructor</span></h2>
                    <p className='getToKnowParaText' tabIndex={0}>
                        Coach K, the founder and lead instructor of Defense Therapy, 
                        brings unparalleled expertise to our program. With six years 
                        of service as an infantry Marine and marksmanship instructor, 
                        Coach K's extensive military background and real-world experience 
                        inform his top-tier instruction. His commitment to ongoing education 
                        ensures that all students benefit from the most current techniques
                        and best practices in firearms training.
                    </p>
                </div>
                <div className='getToKnowDiv getToKnowDivImg'>
                    <h2 className='getToKnowHeading getToKnowHeadingMobile'>Get to know your <span class='textHighlight'>Instructor</span></h2>
                    <img className='aboutPageProfileImg' src='/imgs/coachk.png' alt='Coach K training a student'></img>
                </div>
            </div>
            <Programs/>
            <Philosophy/>
            <Footer/>
        </div>
    )
}
export default About;