import React from "react";

const Philosophy = () => {
    return (
        <div className='philosophySection'>
            <div className='philosophyContent container'>
                <div className='philosophyFlexItem'>
                    <p>
                        At Defense Therapy, we empower individuals to safely, responsibly and confidently 
                        handle firearms. Our goal is to create a safe learning environment where students 
                        develop their skills and adopt a mindset of responsibility and preparedness.
                    </p>
                </div>
                <div className='philosophyFlexItem'>
                    <h2 className='philosophyHeading'>DT <span className='textHighlight'>Philosophy</span></h2>
                </div>
            </div>
        </div>
    )
}
export default Philosophy;