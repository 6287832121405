import React from "react";

const OfferedClasses = () => {
    return (
        <div className='offeredClassesSection'>

            <div className='container'>
                <div>
                    <h2 className='offeredClassesHeading'><span className='textHighlight'>Classes </span> We Offer</h2>
                </div>
                <div className='classCardswrapper'>
                    <div className='cardItemWrapper'>
                        <div className='classCardItem classCardItem1'>
                            <h3 className='classCardHeading'>Maryland CCW<br></br>$200</h3>
                        </div>
                        <div className='cardItemBtn'>
                            <button className='learnMoreBtn' type='button' data-bs-toggle='modal' data-bs-target="#marylandCcwModal">Learn more</button>
                        </div>
                        <div className="modal fade" id="marylandCcwModal" tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title " id="ModalLabel">Maryland CCW</h3>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <p>
                                            Our Maryland CCW class equips you with the essential knowledge and skills for 
                                            legal concealed carry. Covering Maryland laws, firearm safety, handgun operation, 
                                            marksmanship, and concealed carry techniques, the course includes scenario-based 
                                            training for real-life situations. Taught by experienced instructors in a state-of-the-art 
                                            facility, you'll receive personalized attention and ongoing support. Complete the course to 
                                            fulfill Maryland's training requirements for the Wear and Carry Permit.
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='cardItemWrapper'>
                        <div className='classCardItem classCardItem2'>
                            <h3 className='classCardHeading'>Multi Jurisdictional CCW<br></br>$300</h3>
                        </div>
                        <div className='cardItemBtn'>
                            <button className='learnMoreBtn' type='button' data-bs-toggle='modal' data-bs-target="#multiJurCcwModal">Learn more</button>
                        </div>
                        <div className="modal fade" id="multiJurCcwModal" tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title " id="ModalLabel">Multi Jurisdictional CCW</h3>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            <p>
                                                The Multi-Jurisdictional CCW class offered by Defense Therapy covers concealed carry laws for 
                                                Maryland, DC, Virginia, and states with reciprocity. This comprehensive course equips you with 
                                                the knowledge and skills needed to legally carry in multiple jurisdictions. Maryland residents 
                                                receive a free Handgun Qualification License (HQL) as part of the course, and the Arizona CCW 
                                                certification is also included, providing broader coverage for additional states. Ideal for those 
                                                looking to expand their carry privileges across the DMV and beyond.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                    </div>
                    
                    <div className='cardItemWrapper'>
                        <div className='classCardItem classCardItem3'>
                            <h3 className='classCardHeading'>Pistol Marksmanship<br></br>and Safety<br></br>$100</h3>
                        </div>
                        <div className='cardItemBtn'>
                            <button className='learnMoreBtn' type='button' data-bs-toggle='modal' data-bs-target="#pistolMarkModal">Learn more</button>
                        </div>
                        <div className="modal fade" id="pistolMarkModal" tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title " id="ModalLabel">Pistol Marksmanship and Safety</h3>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            <p>
                                                Join our 1-hour Basic Pistol Safety and Marksmanship class to learn essential 
                                                handgun safety, operation, and basic shooting techniques. Perfect for beginners, 
                                                this quick, comprehensive course covers safe handling, proper grip, stance, and 
                                                aiming. Taught by experienced instructors, you'll gain confidence and foundational 
                                                skills for responsible firearm use. Enroll now to start your journey in safe and 
                                                accurate pistol shooting!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className='cardItemWrapper'>
                        <div className='classCardItem classCardItem4'>
                            <h3 className='classCardHeading'>Gun Tastings<br></br>$200</h3>
                        </div> 
                        <div className='cardItemBtn'>
                            <button className='learnMoreBtn' type='button' data-bs-toggle='modal' data-bs-target="#gunTastingsModal">Learn more</button>
                        </div>
                        <div className="modal fade" id="gunTastingsModal" tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title " id="ModalLabel">Gun Tastings</h3>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            <p>
                                                Join our Gun Tastings event to try various pistols and rifles before buying. 
                                                Perfect for exploring options, this session lets you test different firearms 
                                                with expert guidance. Learn about features, handling, and performance in a safe 
                                                environment. Find the perfect fit for your needs and preferences. Sign up for an 
                                                informative, hands-on experience!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OfferedClasses;