import React from "react";
import Navbar from "../components/Navbar";
import Philosophy from "../components/Philosophy"
import Programs from "../components/Programs";
import OfferedClasses from "../components/OfferedClasses";
import Booking from "../components/Booking";
import AboutUs from "../components/AboutUs";
import Reviews from "../components/Reviews";
import Faqs from "../components/Faqs";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";


const Home = () => {
    return (
        <div>
             <Helmet>
                <title>Defense Therapy | Firearm Safety Training for the Real World.</title>
                <meta 
                    name="description" 
                    content="Defense Therapy: Empowering individuals with firearm safety and confidence. Offering Basic Firearms Training, Concealed Carry Courses, and Maryland HQL Courses."
                    data-rh="true"> 
                </meta>
                <link rel="canonical" href="/"></link>
            </Helmet>
            <Navbar/>
            <div className='homeSection'>
                <div className='homeSectionContent container'>
                    <h1 className='landingHeading'>Firearms Safety Training for<br></br>the Real World</h1>
                    <h2 className='landingText'>Get Trained with <span className='textHighlight'>Defense Therapy</span>: Concealed Carry, Home Safety, and Gun Safety from Experts</h2>
                    <br></br>
                    <a className='landingBtn' href='https://squareup.com/appointments/buyer/widget/goglug4njcvntf/LMHW1P0587EWV' target="_blank" rel="noopener noreferrer">Book a Class Now</a>
                </div>    
            </div>
            <Philosophy/>
            <Programs/>
            <OfferedClasses/>
            <Booking/>
            <AboutUs/>
            <Reviews/>
            <Faqs/>
            <Contact/>
            <Footer/>
        </div>
    )
}
export default Home;