import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const Blog = () => {
    return (
        <div>
            <Helmet>
                <title>Defense Therapy | Blogs</title>
                <meta 
                    name="description" 
                    content="Defense Therapy: Come checkout our Blogs and learn about the ins and outs of firearm training and ownership."
                    data-rh="true"> 
                </meta>
                <link rel="canonical" href="/blog"></link>
            </Helmet>
            <Navbar/>
            <div className='blogPage'>
                <div className='blogPageContent'>
                    <h2><span className='textHighlight'>Blogs</span> Coming Soon!</h2>
                    <a className="blogPageBackBtn" href='/'>Go back to home</a>
                </div>  
            </div>
            <Footer/>
        </div>
    )
}
export default Blog;