import React from "react";

const Reviews = () => {
    return (
        <div className='reviewsSection'>
            <div className='reviewsWrapper'>
                <div className='reviewItem reviewItem1'>
                    <div id="carouselIndicators" className="carousel slide">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className="reviewsCarouselImg d-block w-100" src="/imgs/peopleshoot.png" alt="Coach K students"/>
                            </div>
                            <div className="carousel-item">
                                <img className="reviewsCarouselImg d-block w-100" src="/imgs/peopleshoot1.png" alt="Coach K students"/>
                            </div>
                            <div className="carousel-item">
                                <img className="reviewsCarouselImg d-block w-100" src="/imgs/peopleshoot2.png" alt="Coach K students"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>


                </div>

                <div className='reviewItem reviewItem2'>
                    <div>
                        <h2 className='reviewsHeading'>Testimonials | <span className='textHighlight'>Reviews</span></h2>
                    </div>
                    <div className='reviewsCardsWrapper'>
                        <div className='reviewsCard container'>
                            <div>
                                <p className='reviewsCardText'>One of the best shooting experiences I've had! Instructor Khari was patient and calm. Always respecting my time and making sure I was safe and had the right equipment.</p>
                            </div>
                            <div className='reviewsCardRating'>
                                <img className='fiveStartSvg' src='/imgs/fiveStars.svg' alt='Five star rating'></img>
                                <p className='reviewsCardText'>~ Gabriela Andrade</p>
                            </div>
                        </div>

                        <div className='reviewsCard container'>
                            <div>
                                <p className='reviewsCardText' tabIndex="0">The wife and I had a great time at the range with Coach K. He was very knowledgeable and took his time educating us on how to use various firearms. I plan on taking his CC license class. I highly recommend him for anyone's needs when it comes to firearms training and education.</p>
                            </div>
                            <div className='reviewsCardRating'>
                                <img className='fiveStartSvg' src='/imgs/fiveStars.svg' alt='Five star rating'></img>
                                <p className='reviewsCardText'>~ Clay Berry</p>
                            </div>
                        </div>

                        <div className='reviewsCard container'>
                            <div>
                                <p className='reviewsCardText'>Khari is very knowledgeable and patient. As the lesson progressed, I became more comfortable and thus, more accurate hitting the target. Simply the BEST!!</p>
                            </div>
                            <div className='reviewsCardRating'>
                                <img className='fiveStartSvg' src='/imgs/fiveStars.svg' alt='Five star rating'></img>
                                <p className='reviewsCardText'>~ Donna</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Reviews;