import React from "react";

const AboutUs = () => {
    return (
        <div>
            <div className='aboutUsDesktop aboutUseWrapper'>
                <div className='aboutUsItem aboutUsItem1'>
                    <h2 className='aboutUsHeading'><span className='textHighlight'>About </span>Us</h2>
                    <p className='aboutUsText container'> 
                        At Defense Therapy, we equip individuals with the knowledge and skills to 
                        handle firearms safely and confidently. Our inclusive learning environment 
                        caters to all skill levels, emphasizing responsibility and preparedness. 
                        Founded by a former Marine Corps infantry Marine and marksmanship instructor, 
                        we provide expert instruction backed by extensive experience. Committed to 
                        continuous improvement, we stay updated on the latest techniques and best practices 
                        to ensure effective training for our students.
                    </p>
                </div>
                <div className='aboutUsItem aboutUsItem2 aboutUsImg'></div>
            </div>


            <div className='aboutUsMobile aboutUsImg'>
                <div className='container'>
                    <h2 className='aboutUsHeading'><span className='textHighlight'>About </span>Us</h2>
                    <p className='aboutUsText container'>
                        At Defense Therapy, we empower individuals with the knowledge and 
                        skills needed to responsibly and confidently handle firearms, focusing on 
                        safety, proficiency, and confidence. Our safe learning environment is designed 
                        for students of all levels, aiming to teach firearm handling while instilling a 
                        mindset of responsibility and preparedness. Committed to continuous 
                        learning, we stay updated with the latest techniques and best practices to provide 
                        students with the most effective and current training available.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default AboutUs;